<template>
  <div class="gap-3 grid grid-cols-3">
    <div class="col-span-1">
      <custom-select
        v-model="year"
        :options="years"
        placeholder="Year"
        class="input"
        @change="change('year')"
      />
    </div>
    <div class="col-span-1">
      <custom-select
        v-model="month"
        :options="months"
        placeholder="Month"
        class="input"
        @change="change('month')"
      />
    </div>
    <div class="col-span-1">
      <custom-select
        v-model="day"
        :options="days"
        placeholder="Day"
        class="input"
        @change="change('day')"
      />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    endDate: {
      type: [Date, String],
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: String,
      default: '-',
    },
    startDate: {
      type: [Date, String],
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      day: '',
      month: '',
      year: '',
    };
  },
  computed: {
    date() {
      if (!this.year || !this.month || !this.day) {
        return '';
      }
      return `${this.year}${this.separator}${this.pad(this.month)}${this.separator}${this.pad(this.day)}`;
    },
    days() {
      const maxDays = [
        31, (this.isLeapYear(this.year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
      ];
      const maxDaysThisMonth = maxDays[this.month ? this.month - 1 : 0];

      const days = [];
      for (let i = 0; i < maxDaysThisMonth; i += 1) {
        const day = i + 1;
        days.push({ title: day, value: `${day}` });
      }
      return days;
    },
    end() {
      const defaultEndYear = new Date();
      defaultEndYear.setFullYear(defaultEndYear.getFullYear() - 18);
      defaultEndYear.setDate(1);
      defaultEndYear.setMonth(11);
      return this.parseDate(this.endDate || defaultEndYear, 'endYear');
    },
    months() {
      const options = [
        { name: 'January', index: 1 },
        { name: 'February', index: 2 },
        { name: 'March', index: 3 },
        { name: 'April', index: 4 },
        { name: 'May', index: 5 },
        { name: 'June', index: 6 },
        { name: 'July', index: 7 },
        { name: 'August', index: 8 },
        { name: 'September', index: 9 },
        { name: 'October', index: 10 },
        { name: 'November', index: 11 },
        { name: 'December', index: 12 },
      ];

      if (this.year === this.end.getFullYear().toString()) {
        const maxMonth = this.end.getMonth() + 1;
        options.splice(maxMonth, options.length - maxMonth);
      }

      if (this.year === this.start.getFullYear().toString()) {
        const minMonth = this.start.getMonth();
        options.splice(0, minMonth + 1);
      }

      const months = [
        ...options.map((option) => ({ title: option.name, value: `${option.index}` })),
      ];
      return months;
    },
    start() {
      const defaultStartYear = new Date();
      defaultStartYear.setFullYear(defaultStartYear.getFullYear() - 100);
      return this.parseDate(this.startDate || defaultStartYear, 'startYear');
    },
    today() {
      return new Date();
    },
    years() {
      const endYear = this.end.getFullYear();
      const startYear = this.start.getFullYear();
      const years = [];

      for (let i = startYear + 1; i < endYear + 1; i += 1) {
        years.push(`${i}`);
      }
      years.reverse();

      return years;
    },
  },
  mounted() {
    this.mapData();
  },
  watch: {
    days(value) {
      if (this.day) {
        const valueStillRelevant = value.find((day) => day.value === this.day);
        if (!valueStillRelevant) {
          this.day = value[0]?.value;
        }
      }
    },
    months(value) {
      if (this.month) {
        const valueStillRelevant = value.find((month) => month.value === this.month);
        if (!valueStillRelevant) {
          this.month = value[0]?.value;
        }
      }
    },
    value(value) {
      if (value !== this.value) {
        this.mapData();
      }
    },
    years() {},
  },
  methods: {
    change() {
      this.$emit('change', this.date);
    },
    isLeapYear(year) {
      return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
    },
    mapData() {
      const date = new Date(this.value);
      if (date.getDay()) {
        const day = date.getDate();
        // this.day = day < 10 ? `${day}` : `${day}`;
        this.day = day;
        const month = date.getMonth() + 1;
        // this.month = month < 10 ? `${month}` : `${month}`;
        this.month = month;
        this.year = `${date.getFullYear()}`;
      }
    },
    pad(number) {
      return Number(number) < 10 ? `0${number}` : number;
    },
    parseDate(date, type) {
      switch (date?.constructor) {
        case Date:
          return date;
        case String:
          return new Date(date);
        default:
          throw new Error(`Unknown Date Specified ${type}`);
      }
    },
  },
};
</script>
